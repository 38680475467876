<template>
  <div class="content-box">
    <div class="inner">
      <div class="main-container">
        <div class="main-wrapper">
          <img src="@/assets/images/info_icon_large.png" class="info-icon" />

          <div class="content">{{ $t('register.authority.dialog1') }}</div>
          <div class="content">{{ $t('register.authority.dialog2') }}</div>

          <div class="action-wrapper">
            <Button class="action-button" @click="goToRegister">
              <div class="action-button-text">{{ $t('register.authority.openLiveAcc') }}</div>
            </Button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Card from '@/components/Card';
import Button from '@/components/common/Button.vue';

export default {
  components: { Card, Button },
  computed: {
    demo() {
      return this.$store.state.common.isDemo;
    }
  },
  methods: {
    async goToRegister() {
      await this.$router.push({ name: 'register' }).catch(() => {});
    }
  }
};
</script>

<style lang="scss" scoped>
@import '@/assets/css/pages/register/authority.scss';
</style>
